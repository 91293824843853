// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocalityScss from "./LocationsIndexLocality.scss";

var css = LocationsIndexLocalityScss;

function LocationsIndexLocality(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: Js_string.slice(0, 30, props.name),
                            className: css.localityLink,
                            href: props.href
                          }),
                      className: css.name
                    }),
                JsxRuntime.jsx("div", {
                      children: String(props.totalLocations),
                      className: css.totalLocations
                    })
              ],
              className: css.locality
            });
}

var make = LocationsIndexLocality;

export {
  css ,
  make ,
}
/* css Not a pure module */
