// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexProviderScss from "./LocationsIndexProvider.scss";

var css = LocationsIndexProviderScss;

function LocationsIndexProvider(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: Js_string.slice(0, 30, props.name),
                            className: css.providerLink,
                            href: Routes_Provider.show(props.slug)
                          }),
                      className: css.name
                    }),
                JsxRuntime.jsx("div", {
                      children: String(props.totalLocations),
                      className: css.totalLocations
                    })
              ],
              className: css.provider
            });
}

var make = LocationsIndexProvider;

export {
  css ,
  make ,
}
/* css Not a pure module */
